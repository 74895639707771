import {Link, useNavigate, useSearchParams} from 'react-router';
import React, {Fragment} from 'react';

import {getPathFromCommand} from './prompt';

export const Command = ({children}: {children: string}) => {
  const path = getPathFromCommand({command: children});
  const navigate = useNavigate();
  const [, setURLSearchParams] = useSearchParams();

  const handleNavigate = () => {
    if (children.includes(' ')) {
      const [inputCommand] = children.split(' ');
      setURLSearchParams({command: inputCommand});
    } else {
      navigate(path);
    }
  };

  return (
    <code className="text-primary">
      &gt;{' '}
      {children.includes(' ') ? (
        <button data-link onClick={handleNavigate}>
          {children}
        </button>
      ) : (
        <Link data-link prefetch="intent" to={path}>
          {children}
        </Link>
      )}
    </code>
  );
};

export const Commands = ({
  commands = {},
}: {
  commands: {[key: string]: React.ReactNode};
}) => {
  return (
    <div className="not-prose grid grid-cols-[auto_1fr] gap-x-8">
      {Object.entries(commands).map(([key, value], i) => (
        <Fragment key={i}>
          <Command>{key}</Command>
          <div>{value}</div>
        </Fragment>
      ))}
    </div>
  );
};
